<template>
  <v-expansion-panels
    v-if="tasks.length"
    :value="currentTask"
    focusable
    class="mt-4 mb-1"
  >
    <v-expansion-panel
      v-for="task in tasks"
      :key="task.id"
      @change="setCurrentTask(task.id)"
    >
      <v-expansion-panel-header>
          <span>
            <v-icon
              :color="STATUS_COLORS[task.status]"
            >
              {{ STATUS_ICONS[task.status] }}
            </v-icon>
            <span v-if="task.floor !== null">{{ task.floor + 1 }} / </span>
            {{ task.name }}
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Task/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Task from './Task'
import { debounce } from 'lodash'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    revision: Object,
  },

  components: {
    Task,
  },

  created () {
    this.$root.$on('fetchTasks', (revisionId) => {
      if (this.revision.id !== revisionId) {
        return null
      }

      const task = this.tasks.find(task => task.status === this.OPEN)
      if (task) {
        this.setCurrentTask(task.id)
      }
    })
  },

  watch: {
    taskId: debounce(function (id) {
      this.fetchFiles(id)
      this.fetchTodos(id)
    }, 100),
  },

  computed: {
    ...mapGetters('tasks', ['tasks', 'taskId', 'currentTask']),
  },

  methods: {
    ...mapActions('tasks', ['fetchFiles']),
    ...mapActions('todos', ['fetchTodos']),
    ...mapMutations('tasks', ['setCurrentTask']),
  },
}
</script>
