<template>
  <div>
    <v-card
      v-for="file in files"
      :key="file.id + '_'"
      class="my-3"
    >
      <v-img
        v-if="file.mime_type.split('/')[0] === 'image'"
        :src="`${$axios.defaults.baseURL}file/${file.id}`"
      >
      </v-img>
      <v-card-subtitle>{{ file.name }}
        <a
          :href="`${$axios.defaults.baseURL}file/${file.id}`"
          class="text-decoration-none float-right"
        >
          <v-icon>
            mdi-download
          </v-icon>
        </a>
      </v-card-subtitle>
    </v-card>

    <Todo
      v-for="todo in todos"
      :key="todo.id"
      :todo="todo"
    />

    <v-switch
      v-if="!todos.length"
      v-model="done"
      :color="STATUS_COLORS[this.task.status]"
      label="Done"
    ></v-switch>
  </div>
</template>

<script>
import Todo from './Todo'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Todo,
  },

  computed: {
    ...mapGetters('project', ['project']),
    ...mapGetters('tasks', ['task', 'files']),
    ...mapGetters('todos', ['todos']),

    done: {
      get () {
        return this.task.status !== this.OPEN
      },
      set (val) {
        this.task.status = val ? this.PENDING : this.OPEN
        this.updateTaskEmployee(this.task)
      },
    },
  },

  methods: {
    ...mapActions('tasks', ['updateTaskEmployee']),
  },
}
</script>
