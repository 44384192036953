<template>
  <EmployeeLayout
    :title="project.job_id"
    :wpp-link="project.wpp_link"
  >
    <v-expansion-panels
      v-if="revisions.length"
      :value="currentRevision"
      focusable
      class="mb-5"
    >
      <v-expansion-panel
        v-for="(revision, i) in revisions"
        :key="revision.id"
        @change="setCurrentRevision(i)"
      >
        <v-expansion-panel-header>
          <span>
            <v-icon
              :color="STATUS_COLORS[revision.status]"
            >
              {{ STATUS_ICONS[revision.status] }}
            </v-icon>
            {{ revision.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Revision :revision="revision"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
        color="primary"
        @click="askForCheck(project)"
        :disabled="askForCheckDisabled"
    >
      <v-icon left>mdi-check-outline</v-icon>
      Ask for check
    </v-btn>
  </EmployeeLayout>
</template>

<script>
import EmployeeLayout from '../../layouts/EmployeeLayout'
import Revision from '../../components/Revision'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'EmployeeProject',

  components: {
    EmployeeLayout,
    Revision,
  },

  data: () => ({
    askForCheckClicked: false,
  }),

  watch: {
    currentRevision: async function (index) {
      const revision = this.revisions[index]
      if (!revision) {
        return null
      }
      await this.fetchTasks(revision.id)
      this.$root.$emit('fetchTasks', revision.id)
    },
  },

  async created () {
    await this.fetchProject(this.$route.params.job_id)
    await this.fetchRevisions(this.project.id)

    const index = this.revisions.findIndex(revision => revision.status < this.ACCEPTED)
    if (index !== -1) {
      this.setCurrentRevision(index)
    }
  },

  computed: {
    ...mapGetters('project', ['project']),
    ...mapGetters('revisions', ['revisions', 'currentRevision']),
    askForCheckDisabled () {
      return this.askForCheckClicked || this.revisions.some(i => i.status === 1)
    },
  },

  methods: {
    ...mapActions('project', ['fetchProject']),
    ...mapActions('revisions', ['fetchRevisions']),
    ...mapActions('tasks', ['fetchTasks']),
    ...mapMutations('revisions', ['setCurrentRevision']),

    async askForCheck (project) {
      this.askForCheckClicked = true
      await window.axios.get(`project/${project.job_id}/check`)
    },
  },
}
</script>
