<template>
  <div
    class="d-flex"
  >
    <v-switch
      v-model="accepted"
      :persistent-hint="true"
      :color="STATUS_COLORS[this.ACCEPTED]"
    >
    </v-switch>
    <v-checkbox
      v-model="todo.done"
      @change="changeTodoStatus"
      :color="STATUS_COLORS[todo.status]"
    >
    </v-checkbox>
    <v-textarea
      ref="text"
      v-model="todo.text"
      label="Todo"
      rows="1"
      auto-grow
      @change="updateTodo(todo)"
      @keyup.enter.exact="focusTodo"
      hint="To save the field, lose focus | Enter -> Add new todo | Shift + Enter -> New line"
    ></v-textarea>
    <div
      class="d-flex align-center"
    >
      <v-icon
        color="red"
        @click="deleteTodo(todo.id)"
      >
        mdi-delete
      </v-icon>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    todo: Object,
  },

  computed: {
    ...mapGetters('tasks', ['task']),
    ...mapGetters('todos', ['todos']),

    accepted: {
      get () {
        return this.todo.status === this.ACCEPTED
      },
      set (val) {
        this.todo.done = val
        this.todo.status = val ? this.ACCEPTED : this.OPEN
        this.updateTodo(this.todo)
      },
    },
  },

  methods: {
    ...mapActions('todos', ['addTodo', 'deleteTodo', 'updateTodo']),

    changeTodoStatus () {
      this.todo.status = this.todo.done ? this.PENDING : this.OPEN
      this.updateTodo(this.todo)
    },

    async focusTodo () {
      await this.addTodo(this.task.id)
      this.$parent.$refs.formTodo.slice(-1)[0].$refs.text.focus()
    },
  },
}
</script>
