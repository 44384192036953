<template>
  <AdminLayout
    :title="project.job_id"
    :project="project"
  >
    <v-expansion-panels
      v-if="loaded && revisions.length"
      :value="currentRevision"
      focusable
      class="mb-5"
    >
      <v-expansion-panel
        v-for="(revision, i) in revisions"
        :key="revision.id"
        @change="setCurrentRevision(i)"
      >
        <v-expansion-panel-header>
          <span>
            <v-icon
              :color="STATUS_COLORS[revision.status]"
            >
              {{ STATUS_ICONS[revision.status] }}
            </v-icon>
            {{ revision.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FormRevision :revision="revision"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn
      color="primary"
      @click="addRevision(project.id)"
    >
      <v-icon left>mdi-plus</v-icon>
      Revision
    </v-btn>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import FormRevision from '../../components/FormRevision'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AdminProject',

  components: {
    FormRevision,
    AdminLayout,
  },

  data: () => ({
    loaded: false,
  }),

  mounted () {
    window.addEventListener('keypress', e => {
      if (e.ctrlKey && e.code === 'Enter') {
        this.addTask(this.revisions[this.currentRevision].id)
      }
    })
  },

  watch: {
    currentRevision: async function (index) {
      const revision = this.revisions[index]
      if (!revision) {
        return null
      }
      await this.fetchTasks(revision.id)
      this.$root.$emit('fetchTasks', revision.id)
    },
  },

  async created () {
    this.setCurrentRevision(999)
    await this.fetchProject(this.$route.params.job_id)
    await this.fetchRevisions(this.project.id)

    const index = this.revisions.findIndex(revision => revision.status < this.ACCEPTED)
    if (index !== -1) {
      this.setCurrentRevision(index)
    }
    this.loaded = true
  },

  computed: {
    ...mapGetters('project', ['project']),
    ...mapGetters('revisions', ['revisions', 'currentRevision']),
  },

  methods: {
    ...mapActions('project', ['fetchProject', 'updateProject']),
    ...mapActions('revisions', ['fetchRevisions', 'addRevision']),
    ...mapActions('tasks', ['fetchTasks', 'addTask']),
    ...mapMutations('revisions', ['setCurrentRevision']),
  },
}
</script>
