<template>
  <v-app>
    <ValidationErrors/>

    <v-app-bar
      app
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <a
          v-if="wppLink"
          :href="wppLink"
          target="_blank"
      >
        {{ wppLink }}
      </a>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ValidationErrors from '../components/ValidationErrors'

export default {
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: '%s | Revision Tool',
    }
  },

  props: {
    title: String,
    wppLink: {
      type: String,
      default: null,
    },
  },

  components: {
    ValidationErrors,
  },
}
</script>
