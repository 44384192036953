<template>
  <div>
    <v-row
      v-if="project.floors_number"
      class="mt-2"
    >
      <v-col
        cols="auto"
        class="d-flex align-center"
      >
        Floor
      </v-col>
      <v-col>
        <v-slide-group>
          <v-btn-toggle
            v-model="task.floor"
            @change="updateTask(task)"
            dense
          >
            <v-btn
              v-for="floor in project.floors_number"
              :key="floor"
            >
              {{ floor }}
            </v-btn>
          </v-btn-toggle>
        </v-slide-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="task.name"
          label="Name"
          @change="updateTask(task)"
          hint="To save the field, lose focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="task.status"
          :items="STATUS_NAMES"
          label="Status"
          @change="changeTaskStatus(task.status)"
        ></v-select>
      </v-col>
    </v-row>

    <file-pond
      name="file"
      allow-multiple="true"
      :files="pondFiles"
      :server="pondServer"
      @activatefile="downloadFile"
      credits="#, Click on file to download"
    />

    <FormTodo
      ref="formTodo"
      v-for="todo in todos"
      :key="todo.id"
      :todo="todo"
    />

    <v-row>
      <v-col
        class="mt-2 d-flex justify-space-between"
      >
        <v-btn
          color="primary"
          @click="focusTodo"
        >
          <v-icon left>mdi-plus</v-icon>
          Todo
        </v-btn>
        <v-btn
          class="white--text"
          :color="STATUS_COLORS[ACCEPTED]"
          @click="changeTaskStatus(ACCEPTED)"
        >
          <v-icon left>{{ STATUS_ICONS[ACCEPTED] }}</v-icon>
          Accept
        </v-btn>
        <v-btn
          class="white--text"
          :color="STATUS_COLORS[OPEN]"
          @click="changeTaskStatus(OPEN)"
        >
          <v-icon left>{{ STATUS_ICONS[OPEN] }}</v-icon>
          Deny
        </v-btn>
        <v-btn
          class="white--text"
          color="red"
          @click="deleteTask(task.id)"
        >
          <v-icon left>mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormTodo from './FormTodo'
import vueFilePond from 'vue-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { mapActions, mapGetters } from 'vuex'

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
)

export default {
  components: {
    FormTodo,
    FilePond,
  },

  computed: {
    ...mapGetters('project', ['project']),
    ...mapGetters('tasks', ['task', 'files']),
    ...mapGetters('todos', ['todos']),

    pondServer () {
      return {
        url: this.$axios.defaults.baseURL + 'file',
        headers: this.$axios.defaults.headers.common,
        process: {
          ondata: formData => {
            formData.append('task_id', this.task.id)
            return formData
          },
        },
        load: '/',
        revert: this.deleteFile,
        remove: this.deleteFile,
      }
    },

    pondFiles () {
      return this.files.map(file => ({
        source: file.id + '',
        options: {
          type: 'local',
        },
      }))
    },
  },

  methods: {
    ...mapActions('tasks', ['updateTask', 'updateTaskStatus', 'deleteTask']),
    ...mapActions('todos', ['addTodo', 'fetchTodos']),

    async changeTaskStatus (val) {
      this.task.status = val
      await this.updateTaskStatus(this.task)
      await this.fetchTodos(this.task.id)
    },

    async deleteFile (id, load, error) {
      try {
        await this.$axios.delete(`file/${id}`)
      } catch (e) {
        error(e)
      }
      load()
    },

    async downloadFile (file) {
      const res = await this.$axios.get(`file/${file.serverId}`, {
        responseType: 'blob',
      })
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.filename)
      link.click()
      window.URL.revokeObjectURL(url)
    },

    async focusTodo () {
      await this.addTodo(this.task.id)
      this.$refs.formTodo.slice(-1)[0].$refs.text.focus()
    },
  },
}
</script>
