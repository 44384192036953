<template>
  <div class="d-flex align-center">
    <v-checkbox
      v-model="todo.done"
      @change="changeTodoStatus"
      :color="STATUS_COLORS[todo.status]"
    >
      <template v-slot:label>
        <label
          v-if="todo.text"
          v-html="todo.text.replaceAll('\n', '<br>')"
        >
        </label>
      </template>
    </v-checkbox>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          class="ml-auto"
          @click="copyToClipboard"
        >
          <v-icon
            color="primary"
          >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
      <span>Copy to clipboard</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    todo: Object,
  },

  methods: {
    ...mapActions('todos', ['updateTodoEmployee']),
    ...mapMutations(['setValidationErrors']),

    changeTodoStatus () {
      this.todo.status = this.todo.done ? this.PENDING : this.OPEN
      this.updateTodoEmployee(this.todo)
    },

    async copyToClipboard () {
      await navigator.clipboard.writeText(this.todo.text)
      this.setValidationErrors({ errors: { 0: 'Text copied to clipboard' } })
    },
  },
}
</script>
