<template>
  <component :is="view"></component>
</template>

<script>
import EmployeeProject from './employee/Project'
import AdminProject from './admin/Project'
import { mapGetters } from 'vuex'

export default {
  name: 'Project',

  components: {
    EmployeeProject,
    AdminProject,
  },

  computed: {
    ...mapGetters('user', ['user']),

    view () {
      return this.user.token ? 'AdminProject' : 'EmployeeProject'
    },
  },
}
</script>
