<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="revision.name"
          label="Name"
          @change="updateRevision(revision)"
          hint="To save the field, lose focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="revision.status"
          :items="STATUS_NAMES"
          label="Status"
          @change="updateRevision(revision)"
        ></v-select>
      </v-col>
    </v-row>

    <v-expansion-panels
      v-if="tasks.length"
      :value="currentTask"
      focusable
      class="mb-5"
    >
      <v-expansion-panel
        v-for="task in tasks"
        :key="task.id"
        @change="setCurrentTask(task.id)"
      >
        <v-expansion-panel-header>
          <span>
            <v-icon
              :color="STATUS_COLORS[task.status]"
            >
              {{ STATUS_ICONS[task.status] }}
            </v-icon>
            <span v-if="task.floor !== null">{{ task.floor + 1 }} / </span>
            {{ task.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FormTask v-if="loaded"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="addTask(revision.id)"
        >
          <v-icon left>mdi-plus</v-icon>
          Task
        </v-btn>
      </template>
      <span>Ctrl + Enter</span>
    </v-tooltip>
  </div>
</template>

<script>
import FormTask from './FormTask'
import { debounce } from 'lodash'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    revision: Object,
  },

  components: {
    FormTask,
  },

  data: () => ({
    loaded: false,
  }),

  created () {
    this.$root.$on('fetchTasks', (revisionId) => {
      if (this.revision.id !== revisionId) {
        return null
      }

      const task = this.tasks.find(task => task.status < this.ACCEPTED)
      if (task) {
        this.setCurrentTask(task.id)
      }
    })
  },

  watch: {
    taskId: debounce(async function (id) {
      this.loaded = false
      await this.fetchFiles(id)
      await this.fetchTodos(id)
      this.loaded = true
    }, 100),
  },

  computed: {
    ...mapGetters('tasks', ['tasks', 'taskId', 'currentTask']),
  },

  methods: {
    ...mapActions('revisions', ['updateRevision']),
    ...mapActions('tasks', ['addTask', 'fetchFiles']),
    ...mapActions('todos', ['fetchTodos']),
    ...mapMutations('tasks', ['setCurrentTask']),
  },
}
</script>
